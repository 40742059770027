var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c("server-time-clock"),
      _c("current-class-application", {
        attrs: {
          "show-filter": false,
          init: _vm.timeRange,
          showPage: _vm.bookClassConstants.SHOW_PAGE.BOOK_CLASS,
        },
      }),
      _c("current-class-application", {
        attrs: {
          showPage: _vm.bookClassConstants.SHOW_PAGE.BOOK_CLASS_RECORD,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }